import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useMsal } from '@azure/msal-react';
import './CreateMilestone.css';

const CreateMilestone = () => {
  const [creationMethod, setCreationMethod] = useState('ai');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [oneRowSumup, setOneRowSumup] = useState('');
  const [extensiveSumup, setExtensiveSumup] = useState('');
  const [tag, setTag] = useState('Personal');
  const [milestoneUserInput, setMilestoneUserInput] = useState('');
  const [response, setResponse] = useState(null);
  const [saving, setSaving] = useState(false);

  const { accounts } = useMsal();
  const user = accounts.length > 0 ? accounts[0] : null;
  const userId = user ? user.localAccountId : null;

  const handleGenerateMilestone = (e) => {
    e.preventDefault();
    setSaving(true);

    const newMilestone = { milestoneUserInput };

    fetch('https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/generate-milestone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newMilestone),
    })
      .then((res) => res.json())
      .then((data) => {
        setResponse(data);
        setTitle(data.title || '');
        setDate(data.date || '');
        setOneRowSumup(data.oneRowSumup || '');
        setExtensiveSumup(data.extensiveSumup || '');
        setTag(data.tag || 'Personal');
      })
      .catch((error) => console.error('Error generating milestone:', error))
      .finally(() => setSaving(false));
  };

  const handleSave = () => {
    if (!userId) {
      alert('User ID not found.');
      return;
    }

    const milestoneToSave = {
      userId,
      title,
      date,
      oneRowSumup,
      extensiveSumup,
      tag,
      creationMethod, // Aggiungi il metodo di creazione
    };

    fetch('https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestone-create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(milestoneToSave),
    })
      .then((res) => res.json())
      .then((data) => {
        alert('Milestone saved successfully!');
        console.log('Saved milestone:', data);
      })
      .catch((error) => console.error('Error saving milestone:', error));
  };

  return (
    <div className="create-milestone-container">
      <h2>Crea Milestone</h2>

      {/* Metodo di creazione */}
      <div className="creation-method">
        <button
          className={creationMethod === 'ai' ? 'active' : ''}
          onClick={() => setCreationMethod('ai')}
        >
          Genera con l'AI
        </button>
        <button
          className={creationMethod === 'manual' ? 'active' : ''}
          onClick={() => setCreationMethod('manual')}
        >
          Crea Manualmente
        </button>
      </div>

      {/* Form per creazione manuale */}
      {creationMethod === 'manual' && (
        <form>
          <div className="input-group">
            <label htmlFor="title">Titolo</label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="date">Data</label>
            <input
              id="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="oneRowSumup">Descrizione breve</label>
            <input
              id="oneRowSumup"
              type="text"
              value={oneRowSumup}
              onChange={(e) => setOneRowSumup(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="extensiveSumup">Riassunto del Ricordo</label>
            <textarea
              id="extensiveSumup"
              value={extensiveSumup}
              onChange={(e) => setExtensiveSumup(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="tag">Tag</label>
            <select
              id="tag"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              required
            >
              <option value="Personal">Personale</option>
              <option value="Work">Lavoro</option>
              <option value="Education">Formazione</option>
            </select>
          </div>
          <button type="button" onClick={handleSave}>
            Salva Milestone
          </button>
        </form>
      )}

      {/* Form per generazione con AI */}
      {creationMethod === 'ai' && (
        <form onSubmit={handleGenerateMilestone}>
          <div className="input-group">
            <label htmlFor="milestoneUserInput">Descrivi il tuo Ricordo</label>
            <textarea
              id="milestoneUserInput"
              value={milestoneUserInput}
              onChange={(e) => setMilestoneUserInput(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={saving}>
            {saving ? <FaSpinner className="spinner" /> : 'Genera Milestone'}
          </button>
        </form>
      )}

      {/* Mostra il form generato con AI se ci sono dati disponibili */}
      {creationMethod === 'ai' && response && (
        <div className="generated-data">
          <h3>Milestone Generata</h3>
          <div className="input-group">
            <label htmlFor="generatedTitle">Titolo</label>
            <input
              id="generatedTitle"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)} // Permette la modifica manuale
            />
          </div>
          <div className="input-group">
            <label htmlFor="generatedDate">Data</label>
            <input
              id="generatedDate"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)} // Permette la modifica manuale
            />
          </div>
          <div className="input-group">
            <label htmlFor="generatedOneRowSumup">Descrizione breve</label>
            <input
              id="generatedOneRowSumup"
              type="text"
              value={oneRowSumup}
              onChange={(e) => setOneRowSumup(e.target.value)} // Permette la modifica manuale
            />
          </div>
          <div className="input-group">
            <label htmlFor="generatedExtensiveSumup">Riassunto del Ricordo</label>
            <textarea
              id="generatedExtensiveSumup"
              value={extensiveSumup}
              onChange={(e) => setExtensiveSumup(e.target.value)} // Permette la modifica manuale
            />
          </div>
          <div className="input-group">
            <label htmlFor="generatedTag">Tag</label>
            <select
              id="generatedTag"
              value={tag}
              onChange={(e) => setTag(e.target.value)} // Permette la modifica manuale
            >
              <option value="Personal">Personale</option>
              <option value="Work">Lavoro</option>
              <option value="Education">Formazione</option>
            </select>
          </div>
          <button type="button" onClick={handleSave}>
            Salva Milestone
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateMilestone;