import React, { useState, useEffect } from 'react';
import MilestoneCard from './MilestoneCard';
import './TimelinePreview.css';
import { RingLoader } from 'react-spinners'; // Importa l'animazione

function TimelinePreview({ milestones, loading, error }) {
  const [selectedTags, setSelectedTags] = useState({
    Personal: false,
    Work: false,
    Education: false,
  });

  const [currentMilestones, setCurrentMilestones] = useState(milestones || []);
  const [indicatorPosition, setIndicatorPosition] = useState(0); // Stato per la posizione dell'indicatore

  const getYear = (milestone) => new Date(milestone.date).getFullYear();

  const filterMilestones = (milestones) => {
    if (!Object.values(selectedTags).includes(true)) return milestones;
    return milestones.filter((milestone) => {
      return (
        (selectedTags.Personal && milestone.tag === 'Personal') ||
        (selectedTags.Work && milestone.tag === 'Work') ||
        (selectedTags.Education && milestone.tag === 'Education')
      );
    });
  };

  const toggleTag = (tag) => {
    setSelectedTags((prevSelectedTags) => ({
      ...prevSelectedTags,
      [tag]: !prevSelectedTags[tag],
    }));
  };

  const handleDeleteMilestone = (id) => {
    setCurrentMilestones((prevMilestones) =>
      prevMilestones.filter((milestone) => milestone.id !== id)
    );
  };

  // Listener per lo scroll
  useEffect(() => {
    const handleScroll = () => {
      const timelineElement = document.querySelector('.timeline-line');
      if (timelineElement) {
        const rect = timelineElement.getBoundingClientRect();
        const position = Math.max(
          0,
          Math.min(rect.height, window.innerHeight / 2 - rect.top)
        );
        setIndicatorPosition(position);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setCurrentMilestones(milestones);
  }, [milestones]);

  return (

    <div className="timeline-container">
      <div className="filter-container">
        <button
          className={`filter-btn ${selectedTags.Personal ? 'active' : ''}`}
          onClick={() => toggleTag('Personal')}
        >
          Personal
        </button>
        <button
          className={`filter-btn ${selectedTags.Work ? 'active' : ''}`}
          onClick={() => toggleTag('Work')}
        >
          Work
        </button>
        <button
          className={`filter-btn ${selectedTags.Education ? 'active' : ''}`}
          onClick={() => toggleTag('Education')}
        >
          Education
        </button>
      </div>

      <div className="timeline-line">
        {/* Indicatore che segue lo scroll */}
        <div
          className="timeline-indicator"
          style={{ top: `${indicatorPosition}px` }}
        ></div>
      </div>

      {loading ? (
        <div className="loading-container">
          <RingLoader size={100} color="#36d7b7" loading={loading} />
        </div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        filterMilestones(currentMilestones).map((milestone, index) => {
          const isNewYear =
            index === 0 || getYear(milestone) !== getYear(currentMilestones[index - 1]);

          return (
            <React.Fragment key={index}>
              {isNewYear && (
                <div className="year-separator">
                  <span>{getYear(milestone)}</span>
                </div>
              )}

              <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                <MilestoneCard
                  milestone={milestone}
                  onDelete={handleDeleteMilestone}
                />
              </div>
            </React.Fragment>
          );
        })
      )}
    </div>
  );
}

export default TimelinePreview;