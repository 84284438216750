import React, { useState, useRef, useEffect } from 'react';
import './MilestoneCard.css';

const MilestoneCard = ({ milestone, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLeft, setIsLeft] = useState(false);
  const [goals, setGoals] = useState([]);
  const [successes, setSuccesses] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [isGoalsVisible, setIsGoalsVisible] = useState(false);
  const [isSuccessesVisible, setIsSuccessesVisible] = useState(false);
  const [isChallengesVisible, setIsChallengesVisible] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedSuccess, setSelectedSuccess] = useState(null);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessesModalOpen, setIsSuccessesModalOpen] = useState(false);
  const [isChallengesModalOpen, setIsChallengesModalOpen] = useState(false);
  const [formMode, setFormMode] = useState(null); // 'manual' o 'ai'
  const [titleError, setTitleError] = useState(false);
  const [successTitleError, setSuccessTitleError] = useState(false);
  const [challengeTitleError, setChallengeTitleError] = useState(false);
  
  const [newGoal, setNewGoal] = useState({
    title: '', 
    description: '', 
    completionDate: '', 
    growthImpact: '' 
  });

  const [newSuccess, setNewSuccess] = useState({
  title: '',
  description: '',
  growthImpact: '',
  emotion: '',
  lessonLearned: '',
  recognition: ''
});

const [newChallenge, setNewChallenge] = useState({
  title: '',
  description: '',
  difficultyLevel: '',
  deadline: ''
});



  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      const cardPosition = cardRef.current.getBoundingClientRect();
      setIsLeft(cardPosition.left < window.innerWidth / 2);
    }

    // Recupera i goals appena la componente viene montata
    const fetchGoals = async () => {
      try {
        const response = await fetch(
          `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestone/${milestone.id}/goals`
        );
        if (response.ok) {
          const data = await response.json();
          setGoals(data);
        } else {
          console.error('Errore nel recupero dei goals:', response.statusText);
        }
      } catch (error) {
        console.error('Errore durante il recupero dei goals:', error);
      }
    };

    const fetchSuccesses = async () => {
    try {
        const response = await fetch(
          `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestone/${milestone.id}/reflections/successes`
        );
        if (response.ok) {
          const data = await response.json();
          setSuccesses(data);
        } else {
          console.error('Errore nel recupero dei successes:', response.statusText);
        }
      } catch (error) {
        console.error('Errore durante il recupero dei successes:', error);
      }
    };

    const fetchChallenges = async () => {
      try {
        const response = await fetch(
          `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestone/${milestone.id}/reflections/challenges`
        );
        if (response.ok) {
          const data = await response.json();
          setChallenges(data);
        } else {
          console.error('Errore nel recupero dei challenges:', response.statusText);
        }
      } catch (error) {
        console.error('Errore durante il recupero dei challenges:', error);
      }
    };

    fetchGoals();
    fetchSuccesses();
    fetchChallenges();

  }, [milestone.id]);

  const toggleExpansion = () => {
    if (!isExpanded) {
      setIsExpanded(true); // Permette l'espansione solo se non � gi� espansa
    }
  };

  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    setShowConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestone/${milestone.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: milestone.userId,
          }),
        }
      );

      if (response.ok) {
        onDelete(milestone.id);
      } else {
        console.error('Errore nell\'eliminazione:', response.statusText);
      }
    } catch (error) {
      console.error('Errore durante l\'eliminazione:', error);
    }
  };

  const getMarkerColor = (tag) => {
    switch (tag) {
      case 'Work':
        return '#008000';
      case 'Education':
        return '#F8980C';
      case 'Personal':
        return '#007bff';
      default:
        return '#ddd';
    }
  };

  const handleGoalsClick = () => {
    setIsGoalsVisible((prevState) => !prevState);
  };

  const handleGoalClick = (goalId) => {
    const goal = goals.find((g) => g.id === goalId);
    setSelectedGoal(goal);
  };

  const handleSuccessesClick = () => {
  setIsSuccessesVisible((prevState) => !prevState);
  };

    const handleSuccessClick = (successId) => {
      const success = successes.find((s) => s.id === successId);
      setSelectedSuccess(success);
    };

    const handleChallengesClick = () => {
      setIsChallengesVisible((prevState) => !prevState);
    };

    const handleChallengeClick = (challengeId) => {
      const challenge = challenges.find((c) => c.id === challengeId);
      setSelectedChallenge(challenge);
    };


  const handleBackToGoals = () => {
    setSelectedGoal(null);
  };

    const handleBackToSuccesses = () => {
    setSelectedSuccess(null);
  };
  const handleBackToChallenges = () => {
    setSelectedChallenge(null);
  };

  const handleOpenGoalsModal = () => {
    setIsModalOpen(true);
    setIsSuccessesModalOpen(false);
    setIsChallengesModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewGoal({ title: '', description: '', completionDate: '', growthImpact: '' }); // Reset form
  };

  const handleOpenSuccessesModal = () => {
      setIsModalOpen(false);
    setIsSuccessesModalOpen(true);
    setIsChallengesModalOpen(false);
    };

    const handleCloseSuccessesModal = () => {
      setIsSuccessesModalOpen(false);
      setNewSuccess({ title: '', description: '', growthImpact: '', emotion: '', lessonLearned: '', recognition: '' }); // Reset form
    };

    const handleOpenChallengesModal = () => {
      setIsModalOpen(false);
      setIsSuccessesModalOpen(false);
      setIsChallengesModalOpen(true);
    };

    const handleCloseChallengesModal = () => {
      setIsChallengesModalOpen(false);
      setNewChallenge({ title: '', description: '', dueDate: '', growthImpact: '' }); // Reset form
    };

// handleNew

  const handleNewGoalChange = (e) => {
    const { name, value } = e.target;
    setNewGoal((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewSuccessChange = (e) => {
    const { name, value } = e.target;
    setNewSuccess((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewChallengeChange = (e) => {
    const { name, value } = e.target;
    setNewChallenge((prev) => ({ ...prev, [name]: value }));
  };

// ===============

  const handleGenerateWithAIButton = () => {
    setFormMode('ai');
  };

  const handleManualEntry = () => {
    setFormMode('manual');
  };

    const handleSaveNewGoal = async () => {
        if (!newGoal.title.trim()) {
        setTitleError(true); // Mostra l'errore
        return;
        }
        setTitleError(false); // Nasconde l'errore quando il titolo � valido
        try {
        const response = await fetch(
            `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/goal`,
            {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                milestoneId: milestone.id,
                title: newGoal.title,
                description: newGoal.description,
                completionDate: newGoal.completionDate,
                growthImpact: newGoal.growthImpact,
            }),
            }
        );

        if (response.ok) {
            const savedGoal = await response.json();
            setGoals((prevGoals) => [...prevGoals, savedGoal]);
            handleCloseModal();
        } else {
            console.error('Errore durante il salvataggio del goal:', response.statusText);
        }
        } catch (error) {
        console.error('Errore durante il salvataggio del goal:', error);
        }
    };

    const handleSaveNewSuccess = async () => {
      if (!newSuccess.title.trim()) {
        setSuccessTitleError(true); // Mostra l'errore se il titolo non � valido
        return;
      }
      setSuccessTitleError(false); // Nasconde l'errore quando il titolo � valido

      try {
        const response = await fetch(
          `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/reflections/success`, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              milestoneId: milestone.id,
              title: newSuccess.title,
              description: newSuccess.description,
              growthImpact: newSuccess.growthImpact,
              emotion: newSuccess.emotion,
              lessonLearned: newSuccess.lessonLearned,
              recognition: newSuccess.recognition
            }),
          }
        );

        if (response.ok) {
          const savedSuccess = await response.json();
          setSuccesses((prevSuccesses) => [...prevSuccesses, savedSuccess]);
          handleCloseSuccessesModal(); // Chiude il modal dopo aver salvato il success
        } else {
          console.error('Errore durante il salvataggio del success:', response.statusText);
        }
      } catch (error) {
        console.error('Errore durante il salvataggio del success:', error);
      }
      };

      const handleSaveNewChallenge = async () => {
      if (!newChallenge.title.trim()) {
        setChallengeTitleError(true); // Mostra l'errore se il titolo non � valido
        return;
      }
      setChallengeTitleError(false); // Nasconde l'errore quando il titolo � valido

      try {
        const response = await fetch(
          `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/challenge`, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              milestoneId: milestone.id,
              title: newChallenge.title,
              description: newChallenge.description,
              difficultyLevel: newChallenge.difficultyLevel, // Specifico per Challenge
              deadline: newChallenge.deadline, // Specifico per Challenge
            }),
          }
        );

        if (response.ok) {
          const savedChallenge = await response.json();
          setChallenges((prevChallenges) => [...prevChallenges, savedChallenge]);
          handleCloseChallengesModal(); // Chiude il modal dopo aver salvato la challenge
        } else {
          console.error('Errore durante il salvataggio della challenge:', response.statusText);
        }
      } catch (error) {
        console.error('Errore durante il salvataggio della challenge:', error);
      }
      };


  const handleGenerateWithAI = async () => {

    try {

      console.log("Genero con AI");

      // Fai la richiesta all'endpoint API per generare l'obiettivo con AI
      const response = await fetch(
        `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/generate-goal`,  // Usa il tuo endpoint API
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            goalDescriptionInput: newGoal.aiGoal,  // Passa il valore dell'input AI
            milestoneTitle: milestone.title,
            milestoneDescription: milestone.extensiveSumup
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();  // Supponiamo che l'API ritorni un obiettivo
        setNewGoal({
          title: data.title,
          description: data.description,
          completionDate: data.completionDate,
          growthImpact: data.growthImpact
        }); // Popola il form con i dati dell'obiettivo generato
        handleManualEntry();
      } else {
        console.error('Errore nel generare l\'obiettivo con AI:', response.statusText);
      }
    } catch (error) {
      console.error('Errore durante la generazione dell\'obiettivo con AI:', error);
    }
  };

  const handleGenerateSuccessWithAI = async () => {
  try {
    console.log("Genero Success con AI");

    // Fai la richiesta all'endpoint API per generare il success con AI
    const response = await fetch(
      `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/generate-success`,  // Endpoint per generare success
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          successDescriptionInput: newSuccess.aiSuccess,  // Passa il valore dell'input AI
          milestoneTitle: milestone.title,
          milestoneDescription: milestone.extensiveSumup
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();  // Supponiamo che l'API ritorni un success
      setNewSuccess({
        title: data.title,
        description: data.description,
        growthImpact: data.growthImpact,
        emotion: data.emotion,
        lessonLearned: data.lessonLearned,
        recognition: data.recognition
      }); 
      handleManualEntry();  // Funzione per gestire l'inserimento manuale dopo la generazione
    } else {
      console.error('Errore nel generare il success con AI:', response.statusText);
    }
  } catch (error) {
    console.error('Errore durante la generazione del success con AI:', error);
  }
};


  const handleGenerateChallengeWithAI = async () => {
  try {
    console.log("Genero Challenge con AI");

    // Fai la richiesta all'endpoint API per generare la challenge con AI
    const response = await fetch(
      `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/generate-challenge`,  // Endpoint per generare challenge
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          challengeDescriptionInput: newChallenge.aiChallenge,  // Passa il valore dell'input AI
          milestoneTitle: milestone.title,
          milestoneDescription: milestone.extensiveSumup
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();  // Supponiamo che l'API ritorni una challenge
      setNewChallenge({
        title: data.title,
        description: data.description,
        difficultyLevel: data.difficultyLevel,  // Livello di difficolt� specifico per la Challenge
        deadline: data.deadline  // Deadline per la challenge
      }); 
      handleManualEntry();  // Funzione per gestire l'inserimento manuale dopo la generazione
    } else {
      console.error('Errore nel generare la challenge con AI:', response.statusText);
    }
  } catch (error) {
    console.error('Errore durante la generazione della challenge con AI:', error);
  }
};


  const handleDeleteGoal = async (goalId) => {
  try {
    const response = await fetch(
      `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestones/goal/${goalId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.ok) {
      // Aggiorna lo stato locale rimuovendo il goal eliminato
      setGoals((prevGoals) => prevGoals.filter((goal) => goal.id !== goalId));
    } else {
      console.error('Errore nell\'eliminazione del goal:', response.statusText);
    }
  } catch (error) {
    console.error('Errore durante l\'eliminazione del goal:', error);
  }
};


const handleDeleteSuccess = async (successId) => {
  try {
    const response = await fetch(
      `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestones/success/${successId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.ok) {
      // Aggiorna lo stato locale rimuovendo il success eliminato
      setSuccesses((prevSuccesses) => prevSuccesses.filter((success) => success.id !== successId));
    } else {
      console.error('Errore nell\'eliminazione del success:', response.statusText);
    }
  } catch (error) {
    console.error('Errore durante l\'eliminazione del success:', error);
  }
};


const handleDeleteChallenge = async (challengeId) => {
  try {
    const response = await fetch(
      `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/milestones/success/${challengeId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.ok) {
      // Aggiorna lo stato locale rimuovendo la challenge eliminata
      setChallenges((prevChallenges) => prevChallenges.filter((challenge) => challenge.id !== challengeId));
    } else {
      console.error('Errore nell\'eliminazione della challenge:', response.statusText);
    }
  } catch (error) {
    console.error('Errore durante l\'eliminazione della challenge:', error);
  }
};



  return (
  <div
    className={`milestone-card ${isExpanded ? 'expanded' : ''}`}
    onClick={toggleExpansion}
    ref={cardRef}
  >

  {milestone.verified && (
      <div className={`verification-badge-container ${isLeft ? 'left' : 'right'}`}>
        <div className="verification-badge">&#10003;</div>
        <div className="tooltip">
          OneStories ha verificato l'autenticit&#224; di questa Milestone.
        </div>
      </div>
      )}

    <div
      className="milestone-marker"
      style={{ backgroundColor: getMarkerColor(milestone.tag) }}
    ></div>

    {isExpanded && (
      <button
          className="close-button"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(false);

            setIsGoalsVisible(false); // Chiude anche la tab degli obiettivi
            setIsModalOpen(false);
            setSelectedGoal(null);

            setIsSuccessesVisible(false);
            setIsSuccessesModalOpen(false);
            setSelectedSuccess(null);

            setIsChallengesVisible(false);
            setIsChallengesModalOpen(false);
            setSelectedChallenge(null);

          }}
        >
          &#10005;
      </button>
    )}

    <span className="milestone-tag">{milestone.tag}</span>
    <h3>{milestone.title}</h3>
    <p className="date">{milestone.date}</p>

    {!isExpanded && <p>{milestone.oneRowSumup}</p>}

    {isExpanded && (
      <div className="expanded-content">
        <p>{milestone.extensiveSumup}</p>

        <div className="create-goal-button-container">
          <button className="create-goal-button" onClick={handleOpenGoalsModal}>
            Crea Obiettivo
          </button>
          <button className="create-success-button" onClick={handleOpenSuccessesModal}>
            Crea Successo
          </button>
          <button className="create-challenge-button" onClick={handleOpenChallengesModal}>
            Crea Sfida
          </button>
          <button className="delete-button" onClick={handleConfirmDelete}>
            <span className="delete-icon">&#128465;</span>
          </button>
        </div>


        {showConfirmation && (
          <div className="confirmation-dialog">
            <p>Sei sicuro di voler eliminare questa milestone?</p>
            <div className="confirmation-buttons">
              <button className="confirmation-button" onClick={handleDelete}>S&#236;</button>
              <button className="confirmation-button" onClick={() => setShowConfirmation(false)}>No</button>
            </div>
          </div>
        )}

      </div>
    )}

    <div className={`goals-button ${isLeft ? 'left' : 'right'}`}>
      {goals.length > 0 && (
        <button className="goals-btn" onClick={handleGoalsClick}>
          Obiettivi ({goals.length})
        </button>
      )}
      {successes.length > 0 && (
        <button className="successes-btn" onClick={handleSuccessesClick}>
          Successi ({successes.length})
        </button>
      )}
      {challenges.length > 0 && (
        <button className="challenges-btn" onClick={handleChallengesClick}>
          Sfide ({challenges.length})
        </button>
      )}
    </div>

    {isGoalsVisible && !selectedGoal && (
      <div className={`goals-list ${isGoalsVisible ? 'visible' : ''}`}>
        <h4>Obiettivi</h4>
        <ul>
          {goals.length > 0 ? (
            goals.map((goal) => (
              <li key={goal.id}
              className="goal-item"
              onClick={() => handleGoalClick(goal.id)}
              >
                {goal.title}
                <button
                  className="delete-goal-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteGoal(goal.id);
                  }}
                >X
                </button>
              </li>
            ))
          ) : (
            <li>Nessun goal trovato.</li>
          )}
        </ul>
      </div>
    )}

    {selectedGoal && (
      <div className="goal-details">
        <h4>{selectedGoal.title}</h4>
        <p>Previsto per il <b>{selectedGoal.completionDate}</b></p>
        <p>{selectedGoal.description}</p>
        <p><b>Impatto sulla crescita</b><br></br>{selectedGoal.growthImpact}</p>
        <button className="back-btn" onClick={handleBackToGoals}>
          Torna agli Obiettivi
        </button>
      </div>
    )}

    {isSuccessesVisible && !selectedSuccess && (
      <div className={`goals-list ${isSuccessesVisible ? 'visible' : ''}`}>
        <h4>Successi</h4>
        <ul>
          {successes.length > 0 ? (
            successes.map((success) => (
              <li key={success.id} className="goal-item" onClick={() => handleSuccessClick(success.id)}>
                {success.title}
                <button
                  className="delete-goal-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteSuccess(success.id);
                  }}
                >
                  X
                </button>
              </li>
            ))
          ) : (
            <li>Nessun successo trovato.</li>
          )}
        </ul>
      </div>
    )}

    {selectedSuccess && (
      <div className="goal-details">
        <h4>{selectedSuccess.title}</h4>
        <p>{selectedSuccess.description}</p>
        <p><b>Impatto sulla crescita</b><br></br>{selectedSuccess.growthImpact}</p>
        <p><b>Emozione</b><br></br>{selectedSuccess.emotion}</p>
        <p><b>Lezione appresa</b><br></br>{selectedSuccess.lessonLearned}</p>
        <p><b>Riconoscimenti</b><br></br>{selectedSuccess.recognition}</p>
        <button className="back-btn" onClick={handleBackToSuccesses}>
          Torna ai Successi
        </button>
      </div>
    )}

    {isChallengesVisible && !selectedChallenge && (
      <div className={`goals-list ${isChallengesVisible ? 'visible' : ''}`}>
        <h4>Sfide</h4>
        <ul>
          {challenges.length > 0 ? (
            challenges.map((challenge) => (
              <li key={challenge.id} className="goal-item" onClick={() => handleChallengeClick(challenge.id)}>
                {challenge.title}
                <button
                  className="delete-goal-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteChallenge(challenge.id);
                  }}
                >
                  X
                </button>
              </li>
            ))
          ) : (
            <li>Nessuna sfida trovata.</li>
          )}
        </ul>
      </div>
    )}

    {selectedChallenge && (
      <div className="goal-details">
        <h4>{selectedChallenge.title}</h4>
        <p>Previsto per il <b>{selectedChallenge.dueDate}</b></p>
        <p>{selectedChallenge.description}</p>
        <p><b>Impatto sulla crescita</b><br></br>{selectedChallenge.growthImpact}</p>
        <button className="back-btn" onClick={handleBackToChallenges}>
          Torna alle Sfide
        </button>
      </div>
    )}

    {isModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <h3>Crea Nuovo Obiettivo</h3>

          <div className="modal-buttons">
            <button className="modal-save-buttons" onClick={handleGenerateWithAIButton}>
              Genera con AI
            </button>
            <button className="modal-save-buttons" onClick={handleManualEntry}>
              Manuale
            </button>
          </div>

          {/* Modal Content */}
          {formMode === 'manual' ? (
            <div>
              {/* Form Manuale */}
              <div className="input-container">
                  <input
                    type="text"
                    name="title"
                    value={newGoal.title}
                    onChange={handleNewGoalChange}
                    placeholder="Titolo"
                    className={titleError ? 'input-error' : ''}
                  />
                  {titleError && <div className="error-tooltip">Titolo obbligatorio</div>}
              </div>
              <input
                type="date"
                name="completionDate"
                value={newGoal.completionDate}
                onChange={handleNewGoalChange}
              />
              <textarea
                name="description"
                value={newGoal.description}
                onChange={handleNewGoalChange}
                placeholder="Descrizione"
              ></textarea>
              <input
                type="text"
                name="growthImpact"
                value={newGoal.growthImpact}
                onChange={handleNewGoalChange}
                placeholder="Impatto sulla crescita"
              />

              <div className="modal-buttons">
                <button className="modal-save-buttons" onClick={handleSaveNewGoal}>
                  Salva
                </button>
                <button className="modal-save-buttons" onClick={handleCloseModal}>
                  Annulla
                </button>
              </div>
            </div>
          ) : formMode === 'ai' ? (
            <div>
              {/* Form Genera con AI */}
              <input
                type="text"
                name="aiGoal"
                value={newGoal.aiGoal || ''}
                onChange={handleNewGoalChange}
                placeholder="Descrivi l'obiettivo da generare con AI"
              />
              <button className="generate-ai-button modal-save-buttons" onClick={handleGenerateWithAI}>
                Genera Obiettivo con AI
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )}

    {/* Modal per Successi */}
    {isSuccessesModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <h3>Crea Nuovo Successo</h3>

          <div className="modal-buttons">
            <button className="modal-save-buttons" onClick={handleGenerateWithAIButton}>
              Genera con AI
            </button>
            <button className="modal-save-buttons" onClick={handleManualEntry}>
              Manuale
            </button>
          </div>

          {/* Modal Content */}
          {formMode === 'manual' ? (
            <div>
              {/* Form Manuale Successo */}
              <div className="input-container">
                <input
                  type="text"
                  name="title"
                  value={newSuccess.title}
                  onChange={handleNewSuccessChange}
                  placeholder="Titolo"
                  className={titleError ? 'input-error' : ''}
                />
                {titleError && <div className="error-tooltip">Titolo obbligatorio</div>}
              </div>
              <textarea
                name="description"
                value={newSuccess.description}
                onChange={handleNewSuccessChange}
                placeholder="Descrizione"
              ></textarea>
              <input
                type="text"
                name="growthImpact"
                value={newSuccess.growthImpact}
                onChange={handleNewSuccessChange}
                placeholder="Impatto sulla crescita"
              />
              <input
                type="text"
                name="emotion"
                value={newSuccess.emotion}
                onChange={handleNewSuccessChange}
                placeholder="Emozione"
              />
              <input
                type="text"
                name="lessonLearned"
                value={newSuccess.lessonLearned}
                onChange={handleNewSuccessChange}
                placeholder="Lezione appresa"
              />
              <input
                type="text"
                name="recognition"
                value={newSuccess.recognition}
                onChange={handleNewSuccessChange}
                placeholder="Riconoscimenti"
              />
              <div className="modal-buttons">
                <button className="modal-save-buttons" onClick={handleSaveNewSuccess}>
                  Salva
                </button>
                <button className="modal-save-buttons" onClick={handleCloseSuccessesModal}>
                  Annulla
                </button>
              </div>
            </div>
          ) : formMode === 'ai' ? (
            <div>
              {/* Form Genera con AI per Successi */}
              <input
                type="text"
                name="aiSuccess"
                value={newSuccess.aiSuccess || ''}
                onChange={handleNewSuccessChange}
                placeholder="Descrivi il successo da generare con AI"
              />
              <button className="generate-ai-button modal-save-buttons" onClick={handleGenerateSuccessWithAI}>
                Genera Successo con AI
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )}

    {/* Modal per Sfide */}
    {isChallengesModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <h3>Crea Nuova Sfida</h3>

          <div className="modal-buttons">
            <button className="modal-save-buttons" onClick={handleGenerateWithAIButton}>
              Genera con AI
            </button>
            <button className="modal-save-buttons" onClick={handleManualEntry}>
              Manuale
            </button>
          </div>

          {/* Modal Content */}
          {formMode === 'manual' ? (
            <div>
              {/* Form Manuale Sfida */}
              <div className="input-container">
                <input
                  type="text"
                  name="title"
                  value={newChallenge.title}
                  onChange={handleNewChallengeChange}
                  placeholder="Titolo"
                  className={titleError ? 'input-error' : ''}
                />
                {titleError && <div className="error-tooltip">Titolo obbligatorio</div>}
              </div>
              <input
                type="date"
                name="dueDate"
                value={newChallenge.dueDate}
                onChange={handleNewChallengeChange}
              />
              <textarea
                name="description"
                value={newChallenge.description}
                onChange={handleNewChallengeChange}
                placeholder="Descrizione"
              ></textarea>
              <input
                type="text"
                name="growthImpact"
                value={newChallenge.growthImpact}
                onChange={handleNewChallengeChange}
                placeholder="Impatto sulla crescita"
              />

              <div className="modal-buttons">
                <button className="modal-save-buttons" onClick={handleSaveNewChallenge}>
                  Salva
                </button>
                <button className="modal-save-buttons" onClick={handleCloseChallengesModal}>
                  Annulla
                </button>
              </div>
            </div>
          ) : formMode === 'ai' ? (
            <div>
              {/* Form Genera con AI per Sfide */}
              <input
                type="text"
                name="aiChallenge"
                value={newChallenge.aiChallenge || ''}
                onChange={handleNewChallengeChange}
                placeholder="Descrivi la sfida da generare con AI"
              />
              <button className="generate-ai-button modal-save-buttons" onClick={handleGenerateWithAI}>
                Genera Sfida con AI
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )}



  </div>
);

};

export default MilestoneCard;