import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import NavBar from './NavBar';
import CreateMilestone from './CreateMilestone';
import HeroSection from './HeroSection';
import TimelinePreview from './TimelinePreview';
import FloatingButton from './FloatingButton'; // Importa il pulsante fluttuante
import './App.css';

function App() {
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState('home');
  const [user, setUser] = useState(null);
  const [fadeClass, setFadeClass] = useState('fade');

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    setFadeClass('fade'); // Imposta la pagina con fade all'inizio
    setTimeout(() => setFadeClass('fade active'), 100); // Applica il fade dopo 100ms
  }, [activePage]);

  useEffect(() => {
    // Gestisce l'autenticazione e acquisisce i token
    if (accounts.length > 0 && inProgress === 'none') {
      const account = accounts[0];
      instance
        .acquireTokenSilent({
          account: account,
          scopes: ['openid', 'profile', 'email'],
        })
        .then((response) => {
          const email =
            response.idTokenClaims.preferred_username ||
            response.idTokenClaims.email ||
            response.idTokenClaims.username;
          setUser({ ...account, email });
        })
        .catch((err) => {
          console.error('Errore durante il token silent:', err);
          setError('Authentication error');
        });
    }
  }, [accounts, inProgress, instance]);

  useEffect(() => {
    if (user) {
      fetchMilestones(user.localAccountId); // Carica le milestone specifiche per l'utente
    }
  }, [user]);

  const fetchMilestones = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://osbackend-g6aqbnbxa5dyedfa.italynorth-01.azurewebsites.net/user/${userId}/milestones`
      );
      setMilestones(response.data);
    } catch (err) {
      console.error('Errore durante il caricamento delle milestones:', err);
      setError('Unable to load milestones');
    } finally {
      setLoading(false);
    }
  };

  const renderPage = () => {
    switch (activePage) {
      case 'home':
        return (
          <div className={`page-content ${fadeClass}`}>
            <HeroSection />
            {user && (
              <>
                <div className="refresh-button-container">
                  <button
                    className="refresh-button"
                    onClick={() => fetchMilestones(user.localAccountId)}
                  >
                    Aggiorna
                  </button>
                </div>
                <TimelinePreview
                  milestones={milestones}
                  loading={loading}
                  error={error}
                  fetchMilestones={fetchMilestones}
                />
                {/* Mostra il pulsante fluttuante solo se l'utente � loggato */}
                <FloatingButton onClick={() => setActivePage('create-milestone')} />
              </>
            )}
          </div>
        );
      case 'create-milestone':
        return (
          <div className={`page-content ${fadeClass}`}>
            <CreateMilestone />;
          </div>
        );
      default:
        return <div>Page not found!</div>;
    }
  };

  return (
    <div className="App">
      <NavBar setPage={setActivePage} user={user} setUser={setUser} />
      <div className="page-content">{renderPage()}</div>
    </div>
  );
}

export default App;