import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react'; // Usa il hook useMsal
import './NavBar.css';

const NavBar = ({ setPage, setUser }) => {
  const { instance } = useMsal(); // Ottieni l'istanza da MSAL

  useEffect(() => {
    // Dopo il login, ottieni l'account dell'utente
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      // Imposta l'utente (puoi settare user tramite il setUser)
      setUser(accounts[0]);
    }
  }, [instance, setUser]);

  const login = () => {
    // Avvia il flusso di login
    instance.loginRedirect().catch((error) => {
      console.error('Login failed', error);
    });
  };

  const logout = () => {
    // Esegui il logout
    instance.logoutRedirect().catch((error) => {
      console.error('Logout failed', error);
    });
    setUser(null); // Reset dell'utente
  };

  // Ottieni l'utente autenticato tramite MSAL
  const user = instance.getAllAccounts()[0]; // Ottieni l'account corrente

  return (
    <nav className="navbar">
      <ul>
        <li>
          <button onClick={() => setPage('home')}>Home</button>
        </li>
        {/* Mostra il pulsante Create Milestone solo se l'utente � autenticato */}
        {user && (
          <li>
            <button onClick={() => setPage('create-milestone')}>Crea Milestone</button>
                  </li>

        )}
      </ul>
      <ul className="right">
        {/* Mostra il pulsante di login se l'utente non � autenticato */}
        {!user ? (
          <li>
            <button onClick={login}>Login</button>
          </li>
        ) : (
          <>
            <li>
              <span>Benvenuto/a</span>
              <ul>
                <span><b>{user.idTokenClaims.given_name}</b></span>
              </ul>
            </li>
            <li>
              <button onClick={logout}>Logout</button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;