// HeroSection.js
import React from 'react';
import { useMsal } from '@azure/msal-react'; // Usa il hook useMsal
import './HeroSection.css';

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>Dai valore alla tua storia con <span className="brand-name">OneStories</span></h1>
        <p>Conserva i tuoi momenti preziosi e condividili con il mondo</p>
        <button className="cta-button">Inizia ora</button>
      </div>
    </div>
  );
};

export default HeroSection;